import ng from 'angular';
import { acpDctDisbursementComponentModule } from 'apps/debit-card-transfers-disbursement/components/dct-disbursement';
import acpCore from 'core';
import {
  acpDctDisbursementSection,
  AcpDctDisbursementSectionComponentCtrl
} from './acp-dct-disbursement-section-component';
import './styles/${theme}/core.scss';

export const acpDctDisbursementModule = ng
  .module('acp.section.dct-disbursement', [
    acpCore.name,
    acpDctDisbursementComponentModule.name
  ])
  .component('acpDctDisbursementSection', acpDctDisbursementSection)
  .run((contentSectionCache) => {
    'ngInject';
    contentSectionCache.put('apps/debit-card-transfers-disbursement');
  });

export default acpDctDisbursementModule;
export { AcpDctDisbursementSectionComponentCtrl };
