import ng from 'angular';
import acpDctDisbursementTemplate from './templates/acp-dct-disbursement-section.html';

export class AcpDctDisbursementSectionComponentCtrl
  implements nsUtils.NsComponentController {
  public contactCS: boolean = false;
  constructor(nsComponentDecorator) {
    'ngInject';

    nsComponentDecorator(this, this);
  }
}

export const acpDctDisbursementSection: ng.IComponentOptions = {
  controller: AcpDctDisbursementSectionComponentCtrl,
  controllerAs: 'vm',
  template: acpDctDisbursementTemplate
};
